/* En tu archivo CSS o SCSS */
.btn-primary {
    margin-bottom:25px;
    background-color: #ad33ff !important; /* Cambia este valor al color que desees */
    border-color: #ad33ff !important; /* Cambia este valor al color que desees para el borde */
    color: white !important;
  }
  .btn-primary:hover {
    background-color: #8925cc !important; /* Cambia este valor al color que desees */
    border-color: #8925cc !important; /* Cambia este valor al color que desees para el borde */
    color: white !important;
  }
  

@keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .pulse-animation {
    animation: pulse 2s infinite; /* 2s es la duración de la animación, infinite hace que la animación se repita indefinidamente */
  }
  .transparent-table {
    background-color: transparent;
  }