.App {
  text-align: center;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.boton{
  background-color: #731ada;
  color:white;
}

/* En tu archivo CSS o SCSS */
.btn-primary {
  background-color: #ff5733; /* Cambia este valor al color que desees */
  border-color: #ff5733; /* Cambia este valor al color que desees para el borde */
}


.App-header {
  background-color: #11161f;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #983bd6;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
